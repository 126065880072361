/* make the customizations */

$screen-sm-min: 220px;

// Small tablets (portrait view)
$screen-md-min: 768px;

// Tablets and small desktops
$screen-lg-min: 992px;

// Large tablets and desktops
$screen-xl-min: 1200px;

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

//Diese Farbe ist im Menu, Icon Stift, Tabellen und?
//Overwriting Bootstrap
$theme-colors: (
  //"primary": #18aafa,
        "primary": #022d50,
);

//Warum muss das an dieser stelle sein?
@import "~bootstrap/scss/bootstrap";

html {

  @include sm {

    font-size: 0.9rem;

    h1, h2, h3, h4 {
      font-size: 1.3rem;
    }
  }

  @include md {

    font-size: 1rem;

    h1, h2, h3, h4 {
      font-size: 1.3rem;
    }
  }
  @include lg {
    
    font-size: 0.9rem;

    h1, h2, h3, h4 {
      font-size: 1.3rem;
    }
  }

  @include xl {
    font-size: 1.0rem;

    h1, h2, h3 ,h4{
      font-size: 1.3rem;
    }
  }
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: auto !important;
  font-size: 1rem;
  line-height: 1.5;
  overflow: hidden;
  height: 100% !important;


  .nav-root {
    padding: 5px;
  }

  .nav-burger {
    position: absolute;
    right: 5px;
    top: 3px;
  }

  .nav-avatar {
    position: absolute;
    right: 5rem;
    top: 1rem;
  }

  .MuiAvatar-circular {
    background-color: #18aafa;
  }

  .icon-size-medium {
    font-size: 2.5rem;
  }

  @include sm {
    .nav-menue-hide {
      display: none;
    }
    .nav-burger-hide {
      display: block;
    }
  }

  @include md {
    .nav-menue-hide {
      display: block !important;
    }
    .nav-burger-hide {
      display: none !important;
    }

  }

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}


.base-content {
  padding: 1rem;
  margin: 0 0 1rem;
  border: 0px; 
  border-radius: 3px 3px 0 0;
  background-color: white;
  //War vorher gray
  color: rgb(70, 70, 70);
  overflow: hidden;

  @include sm {
    padding: 0.5rem;
  }

  @include md {
    padding: 0.8rem;
  }

  @include lg {
    padding: 0.8rem;
  }
}

.base-content-grid {
  padding: 1rem;
  margin: 2.3rem 0;
  margin-top: 8px !important;
  border: 1px solid #e6e8e9;
  border-radius: 3px 3px 0 0;
  background-color: white;
  //War vorher gray
  color: rgb(70, 70, 70);
  width: inherit;
  height: inherit;


  @include lg {
  }
}

.base-content-grid-pad {
  padding: 1rem;
  margin: 1rem 0;
  border: 1px solid #e6e8e9;
  border-radius: 3px 3px 0 0;
  background-color: white;
  //War vorher gray
  color: rgb(70, 70, 70);
  width: inherit;
  height: inherit;

}

.table-responsive {
  overflow: hidden;
}

.root {
  flex-grow: 1;
  color: gray;
}

h1, h2, h3, h4 {
  color: rgb(70, 70, 70);
}

//COLORS
.red {
  background-color: red;
}

.font-blue {
  color: #18aafa;
}

.color-w {
  color: white;
}


.dashboard-root {
  flex-grow: 1;
  height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}


.grid-contents {
  //War vorher gray
  color: rgb(70, 70, 70);
  background-color: white;
}


.loading-icon {
  position: relative;
  top: 50%;
  left: 50%;
}


.base-page {
  padding: 1rem;
  padding-bottom: 0rem !important;
  border: none;
  color: gray;
  border-radius: 3px 3px 0 0;
  @include sm {
    padding: 0.5rem;
  }

  @include md {
    padding: 0.8rem;
  }

}

.base-page-dashboard {
  padding: 2px !important;

  @include sm {
    width: 100%;
  }

  @include md {
    width: 100%;
  }

  @include lg {
    margin-left: 210px;
    width: calc(100% - 210px);
  }
}

.align-center {
  display: flex;
  align-items: center;
}

.text-align-center {
  text-align: center !important;
}

.full-size-button {
  width: auto;
  min-width: 250px;
  color: white;
}


//Das Link element ist aus dem React Router Dom zur Navigations
Link {
  color: blue;
}

//Button z.B. neue Energiekennzahl anlegen
button {
  a {
    color: white !important;
    text-decoration: none !important;
  }
}

//Das würde mit id aufgerufen

#test {
  size: 0px;
}

//NAVIGATION

//navbar ist nur navbar gestyled und navbar, navbar-nav beides
.navbar {
  padding-bottom: 0px !important;
  background-color: white;
  color: rgb(6, 49, 180) !important;
}

.navbar, .navbar-nav {
  width: 100%;
  margin-top: 1rem;
  margin-left: 1rem;
 
  .button {
    padding: unset !important;
  }
}

.nav-link-title {
  padding-left: 1rem;
}



//Navigation color when pressed is here!
.active {
  color: #18aafa;
}

//Padding und Font Size der Nav Items
.nav-item {
  font-size: 1.0rem;
  margin: 0.1rem 0;
  white-space: nowrap;
}

// NAVIGATION ENDE

.logout {
  position: absolute;
  bottom: 4rem;
}

.options {
  position: absolute;
  bottom: 7rem;
}


.float-right {
  float: right;
  padding-right: 5px;

}

.max-width {
  width: inherit;
}

.float-right:hover {
  opacity: 0.7;
}

a:hover {
  //font-weight: bolder;
  color: #18aafa;
  text-decoration: none;
}

.messJahr {
  max-width: 150px;
}


.wrap {
  overflow: hidden;
  width: inherit; /* Optional */
}

.oneOfThree {
  float: left;
  width: 33.3%;
  padding-right: 5px;

}

.oneOfToo {
  float: left;
  width: 50%;
  padding-right: 5px;
}


.font-large {
  font-size: 1.3rem;
  line-height: 2.3;
  //font-weight: bold;
  text-align: center;
  padding-top: 1rem;
}

.medium-font {
  font-size: 1.1rem;
  text-align: center;
  color: black;
}

.small-font {
  font-size: 0.7rem;
  text-align: center;
}

//Welcher br ist korrekt?
/*br {
  display: block;
  margin: 5px 0;
} */

br {
  content: "";
  display: block;
  margin: 0.5em 2em;
}

.Modal-MessDaten {
  position: absolute;
  width: 500px;
  height: 500px;
  background-color: white;
  border: 2px solid #000;
  top: 30%;
  left: calc(50% - 200px);
  padding: 20px;

  textarea {
    width: 450px;
    height: 350px;
  }

}

.text-right {
  text-align: "right";
}


.select-minsize {
  min-width: 100px;
  padding-left: 10px;
}

.dashboard-content {
  background-color: white;
  padding: 12px;
}

.float-left {
  float: left;
}

//Logo Styling only
.logo {
  padding: 8px;
  padding-left: 13px;
  padding-bottom: 1px;

  a {
    font-size: 2.0rem;
    font-weight: bold;
    color: #18aafa;
    text-decoration: none;
  }
}

.mb-3 small {
  color: #e74c3c;
  visibility: hidden;
}

.mb-3.error small {
  visibility: visible;
}

.table {
  margin-bottom: 0rem !important;
}

.no-border {
  border: none !important;
}


.mb-3 {
  margin-bottom: 1.5rem !important;
}

.link-button {
  background-color: unset !important;
  border: none !important;
  color: black !important;
}

.red-button {
  background-color: red !important;
  border-color: #be0101 !important;
  color: white !important;
}


.padding-left {
  padding-left: 10px;
}

.margin-left {
  margin-left: 10px;
}

.fixed-cell {
  table-layout: fixed;
}

.table-dialog {
  width: 50%;
  left: 15%;
}

.style-kursiv {
  font-style: italic;
}

.disabled-link {
  color: gray;
  pointer-events: none;
}

//customize MUI Table funcitonier leider nicht
.MuiTableCell-root  {
 font-size: 1.0rem !important;
}

.MuiTableCell-head {
  font-weight: 900 !important;
  padding-top: 20px !important;
}

.dashboard-header {
  padding-bottom: 10px; 
  margin-left: 10px;
}

.dashboard-diagram .MuiInputLabel-root {
	max-width: calc(100% - 46px);
}

/*
Does not work in Firefox :(. Attach a class based on value instead.
.dashboard-diagram label:has(+ .MuiOutlinedInput-root > input:not([value=""])) {
	display: none;
}
*/

.dashboard-diagram label.has-value {
	display: none;
}

.dashboard-diagram legend {
	width: 0;
}

.dashboard-diagram-sort .MuiLink-root {
	color: #0094FF;
	cursor: pointer;
}

.dashboard-diagram .extra-series-list {
	display: grid;
	grid-template-columns: 1fr auto;
	row-gap: 8px;
	column-gap: 16px;
	color: rgb(70, 70, 70);
	font-size: 14px;
}

.dashboard-diagram .extra-series-list .process-value-label {
	font-weight: 700;
	text-align: right;
}

.dashboard-diagram .extra-series-list .process-ratio-label {
	text-align: right;
}

.dashboard-diagram .extra-series-list .process-ratio {
	height: 3px;
	background: #0094FF;
}

.dashboard-diagram .extra-series-list-actions {
	display: flex;
	justify-content: center;
}

.apexcharts-datalabel-label {
	font-weight: 500;
	font-size: 12px;
	transform: translate(0px, 5px);
}

.apexcharts-datalabel-value {
	font-weight: 500;
	font-size: 20px;
	transform: translate(0px, -5px);
}

.dashboard-diagram h3 {
	font-weight: 500;
	font-size: 16px;
	margin-bottom: 14px;
}

.dashboard-diagram-sort {
	display: flex;
	align-items: center;
	gap: 16px;
}

.dashboard-diagram-sort-label {
	font-weight: 500;
	font-size: 16px;
	color: rgb(70, 70, 70);
	flex: 1 0 auto;
}

.dashboard-diagram-no-series {
	padding: 16px 0;
}

.dasboard-diagrams-container {
	margin-bottom: 120px;
}

.apexcharts-yaxis-label.dark-label, .apexcharts-xaxis-label.dark-label {
	color: rgb(70, 70, 70);
}

.apexcharts-yaxis-label.light-label, .apexcharts-xaxis-label.light-label {
	color: #A19D9D;
}

// Make transform work in Webkit. Webkit does not allow transforms without unit, which is default in apex charts.
.webkit-offset-fix .apexcharts-svg {
	transform: translate(-30px, 0);
}

@media (min-width: 1200px) {
	.dashboard-content.dashboard-diagrams {
		padding-right: 80px;
	}
}

// customize amplify ui login
#root {
  --amplify-primary-color: #18aafa;
}
